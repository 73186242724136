function createDom(fieldObj) {
    const { htmlDom } = fieldObj;

    if (htmlDom == "div") {
        const { className, children, innerHTML, dataset } = fieldObj;

        let divDom = document.createElement("div");
        divDom.className = className ? className : "";

        if (children && Array.isArray(children)) {
            children.forEach((childDom) => {
                const child = createDom(childDom);

                divDom.appendChild(child);
            });
        }

        if (dataset) {
            for (let attr in dataset) {
                const value = dataset[attr];

                divDom.dataset[attr] = value;
            }
        }

        if (innerHTML) {
            divDom.innerHTML = innerHTML;
        }

        return divDom;
    }

    if (htmlDom == "ul") {
        const { className, children, innerHTML, dataset } = fieldObj;

        let ulDom = document.createElement("ul");
        ulDom.className = className ? className : "";
        ulDom.role = "list";

        if (children && Array.isArray(children)) {
            children.forEach((childDom) => {
                const child = createDom(childDom);

                ulDom.appendChild(child);
            });
        }

        if (dataset) {
            for (let attr in dataset) {
                const value = dataset[attr];

                ulDom.dataset[attr] = value;
            }
        }

        if (innerHTML) {
            ulDom.innerHTML = innerHTML;
        }

        return ulDom;
    }

    if (htmlDom == "li") {
        const { className, children, innerHTML, dataset } = fieldObj;

        let liDom = document.createElement("li");
        liDom.className = className ? className : "";

        if (children && Array.isArray(children)) {
            children.forEach((childDom) => {
                const child = createDom(childDom);

                liDom.appendChild(child);
            });
        }

        if (dataset) {
            for (let attr in dataset) {
                const value = dataset[attr];

                liDom.dataset[attr] = value;
            }
        }

        if (innerHTML) {
            liDom.innerHTML = innerHTML;
        }

        return liDom;
    }

    if (htmlDom == "strong") {
        const { className, innerHTML } = fieldObj;

        let strongDom = document.createElement("strong");
        strongDom.className = className ? className : "";

        if (innerHTML) {
            strongDom.innerHTML = innerHTML;
        }

        return strongDom;
    }

    if (htmlDom == "svg") {
        const { width, height, viewBox, fill, children } = fieldObj;

        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("width", width);
        svg.setAttribute("height", height);
        svg.setAttribute("viewBox", viewBox);
        svg.setAttribute("fill", fill);

        if (children && Array.isArray(children)) {
            children.forEach((childDom) => {
                const child = createDom(childDom);

                svg.appendChild(child);
            });
        }

        return svg;
    }

    if (htmlDom == "path") {
        const { d, stroke, strokeWidth, strokeLinecap, strokeLinejoin } =
            fieldObj;

        let path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
        );
        path.setAttribute("d", d);
        path.setAttribute("stroke", stroke);
        path.setAttribute("stroke-width", strokeWidth);
        path.setAttribute("stroke-linecap", strokeLinecap);
        path.setAttribute("stroke-linejoin", strokeLinejoin);

        return path;
    }
}

function formatCurrency(value, currencyCode, locale = "en-US") {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value);
}

function appendInputFieldToForm(contactForm, name, value) {
    if (!contactForm) return;

    const hiddenInput = document.createElement("input");

    hiddenInput.type = "hidden";
    hiddenInput.name = name;
    hiddenInput.dataset.name = name;
    hiddenInput.value = value;

    contactForm.appendChild(hiddenInput);
}

function toSnakeCase(str) {
    return (
        (str &&
            str
                .match(
                    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
                )
                .map((x) => x.toLowerCase())
                .join("_")) ||
        null
    );
}

function getTimestampInSeconds() {
    return Math.floor(Date.now() / 1000);
}

function cookieHandler() {
    return {
        setCookie: (cname, cvalue, exdays) => {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },

        getCookie: (cname) => {
            let name = cname + "=";
            let ca = document.cookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

        hasCookie: (cname) => {
            let searchedCookie = getCookie(cname);

            return searchedCookie != "";
        },
    };
}

function getParamFromUrl(param) {
    const paramStr = window.location.search.replace("?", "");
    const urlParams = new URLSearchParams(paramStr);

    return urlParams.has(param) ? urlParams.get(param) : "";
}

function getUrlTagsHiddenFields(hiddenFieldsValues) {
    URL_TAGS.forEach((tag) => {
        const tagValue = cookieHandler().getCookie(tag);
        const paramValue = getParamFromUrl(tag);

        if (tagValue !== "" || paramValue !== "") {
            hiddenFieldsValues[tag] = tagValue || paramValue;
        }

    });

    return hiddenFieldsValues;
}

function getCtgProject() {
    return Object.entries(PROD_DOMAINS).find((d) =>
        window.location.href.includes(d[0])
    );
}
